<template>
  <div class="footer-profile-cover">
    <div class="footer-profile-user">
      <img
        v-if="getUser.profilePhotoURL"
        :src="getUser.profilePhotoURL"
        alt="user-proflie-img"
      />
      <img v-else :src="defaultAvatar()" />
      <h3>{{ getUser.name }}</h3>
      <h6 v-if="getUser.title">{{ getUser.title }}</h6>
    </div>
    <div class="footer-profile-contact">
      <a v-if="getUser.phone" role="button" :href="'tel:' + getUser.phone">{{
        getUser.phone
      }}</a>
      <a v-if="getUser.email" role="button" :href="'mailto:' + getUser.email">{{
        getUser.email
      }}</a>
    </div>
    <div class="footer-profile-social">
      <div
        v-if="getAssets?.lo_reviews_url && getAssets.lo_reviews_url !== ''"
        class="cursor-pointer"
        @click="redirectToLOReviews"
      >
        <span class="read-reviews-link">Read Reviews </span>
        <ReadReviewsIcon />
      </div>
      <ul>
        <li v-if="getUser.facebookURL">
          <a :href="getUser.facebookURL" target="_blank"
            ><img src="../assets/images/facebook-icon.svg" alt="facebook icon"
          /></a>
        </li>
        <li v-if="getUser.instagramURL">
          <a :href="getUser.instagramURL" target="_blank"
            ><img
              src="../assets/images/instagram-icon.svg"
              alt="instagram icon"
          /></a>
        </li>
        <li v-if="getUser.twitterURL">
          <a :href="getUser.twitterURL" target="_blank"
            ><img src="../assets/images/twitter-icon.svg" alt="twitter icon"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReadReviewsIcon from "./Icons/ReadReviewsIcon.vue";

export default {
  name: "Profile-Info",
  components: { ReadReviewsIcon },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getAssets", "getUser"]),
  },
  methods: {
    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },

    redirectToLOReviews() {
      window.open(this.getAssets.lo_reviews_url + "/reviews", "_blank");
    },
  },
};
</script>

<style>
.footer-profile-cover {
  display: inline-flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  background-color: #ffffff;
  padding: 15px 36px 16px;
  margin-top: 0px;
  border-top: 1px solid #d9dae0;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.footer-profile-user {
  position: relative;
  padding-left: 68px;
  min-height: 55px;
  min-width: 275px;
  padding-right: 10px;
}
.footer-profile-user img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 2px;
  left: 0;
}
.footer-profile-user h3 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  word-break: break-word;
}
.footer-profile-user h6 {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px 4px;
  font-size: 16px;
  margin-bottom: 0px;
  display: inline-block;
  word-break: break-word;
}

.footer-profile-contact {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: auto;
  gap: 5px;
}
.footer-profile-contact a {
  color: #222222;
  text-decoration: underline;
  font-size: 16px;
  width: 100%;
  word-break: break-word;
}

.footer-profile-social {
  display: inline-grid;
  gap: 11px;
}
.footer-profile-social p {
  margin-bottom: 4px;
}
.footer-profile-social p a {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.footer-profile-social p a img {
  width: 13px;
  margin-left: 4px;
}
.footer-profile-social ul {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}
.footer-profile-social ul li a img {
  width: 20px;
}
@media (max-width: 767px) {
  .footer-profile-cover {
    gap: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .footer-profile-cover {
    padding: 15px;
  }
  .footer-profile-user {
    width: 100%;
    min-width: 100%;
    padding-left: 62px;
  }
  .footer-profile-user h3 {
    font-size: 16px;
  }
  .footer-profile-user img {
    width: 50px;
    height: 50px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.read-reviews-link {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--primary-color);
}
</style>
<template>
  <div class="sidebar">
    <aside>
      <div class="logo">
        <a>
          <img v-if="teamLogo" :src="teamLogo" alt="Team-Logo" />
        </a>
      </div>
      <div
        @click="toggleMobileMenu"
        :class="toggleSidebar ? 'open' : ''"
        id="menu-icon"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul
        :class="
          mobileMenu ? (toggleSidebar ? 'open-navbar' : 'close-navbar') : ''
        "
        :style="`height: calc(100vh - ${ulHeight + 59}px)`"
      >
        <li
          v-for="(tab, index) in defaultTabs"
          :key="index"
          @mouseenter="hoveredTab = index + 1"
          @mouseleave="hoveredTab = 0"
          :class="currentPage.includes(tab.toLowerCase()) ? 'active' : ''"
        >
          <router-link :to="`/admin/${tab.toLowerCase()}`">
            <i :class="getSideBarIcons(tab)"></i>
            {{ tab.replaceAll("-", " ") }}
          </router-link>
          <img
            v-show="
              mobileMenu ||
              hoveredTab === index + 1 ||
              getAssets.pinnedTab === tab
            "
            role="button"
            @click="pinTheTab(tab)"
            :src="tabWithPin(tab)"
          />
        </li>
        <li>
          <router-link :to="'/portal/logout'">
            <i class="fas fa-sign-out-alt"></i>
            Logout
          </router-link>
        </li>

        <div class="user-info respo-btnset-cov">
          <div class="custom-sidebar-div">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#send-mortgage-app-modal"
              class="btn btn-primary rounded custom-sidebar-button"
              @click="updateModalValue"
            >
              Send Mortgage Application
            </button>
          </div>
        </div>
      </ul>

      <!-- <div
        ref="sidebarElRef"
        class="user-info"
        :class="
          mobileMenu ? (toggleSidebar ? 'open-navbar' : 'close-navbar') : ''
        "
      >
        <div class="avatar">
          <img v-if="getUser.profilePhotoURL" :src="getUser.profilePhotoURL" />
          <img v-else :src="defaultAvatar()" />
        </div>
        <div class="name-designation">
          <h5>{{ getUser.name }}</h5>
          <span v-if="getUser.title">{{ getUser.title }}</span>
        </div>
        <div class="contact-social">
          <a
            v-if="getUser.phone"
            role="button"
            :href="'tel:' + getUser.phone"
            >{{ getUser.phone }}</a
          >
          <a
            v-if="getUser.email"
            role="button"
            :href="'mailto:' + getUser.email"
            >{{ getUser.email }}</a
          >
          <div class="d-flex justify-content-between mb-3">
            <ul>
              <li v-if="getUser.facebookURL">
                <a :href="getUser.facebookURL" target="_blank"
                  ><img
                    src="../assets/images/facebook-icon.svg"
                    alt="facebook icon"
                /></a>
              </li>
              <li v-if="getUser.instagramURL">
                <a :href="getUser.instagramURL" target="_blank"
                  ><img
                    src="../assets/images/instagram-icon.svg"
                    alt="instagram icon"
                /></a>
              </li>
              <li v-if="getUser.twitterURL">
                <a :href="getUser.twitterURL" target="_blank"
                  ><img
                    src="../assets/images/twitter-icon.svg"
                    alt="twitter icon"
                /></a>
              </li>
            </ul>
            <div class="equal-housing-logo">
              <img
                src="../assets/images/equal-housing-logo.png"
                alt="Equal Housing Logo"
              />
            </div>
          </div>

          <div
            v-if="getAssets?.lo_reviews_url && getAssets.lo_reviews_url !== ''"
            class="cursor-pointer"
            @click="redirectToLOReviews"
          >
            <span class="read-reviews-link">Read Reviews </span>
            <ReadReviewsIcon />
          </div>
        </div>
      </div> -->
      <div class="user-info">
        <div class="custom-sidebar-div">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#send-mortgage-app-modal"
            class="btn btn-primary rounded custom-sidebar-button"
            @click="updateModalValue"
          >
            Send Mortgage Application
          </button>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import Toasters from "./../mixins/toasters";
// import ReadReviewsIcon from "./Icons/ReadReviewsIcon.vue";

export default {
  name: "Sidebar",
  mixins: [Toasters],
  // components: { ReadReviewsIcon },
  data() {
    return {
      defaultTabs: [],
      teamLogo: "",
      toggleSidebar: false,
      mobileMenu: false,
      hoveredTab: 0,
      windowWidth: window.innerWidth,
      ulHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId", "getUser", "getAssets"]),

    currentPage() {
      return this.$route.path;
    },
  },
  watch: {
    $route() {
      if (this.mobileMenu) this.toggleSidebar = false;
    },

    // toggleSidebar: {
    //   immediate: true,
    //   deep: true,
    //   handler(newVal) {
    //     if (newVal) {
    //       this.$nextTick(() => {
    //         this.sideBarPartialHeight();
    //       });
    //     }
    //   },
    // },

    windowWidth: {
      immediate: true,
      handler(newVal) {
        // this.$nextTick(() => {
        //   this.sideBarPartialHeight();
        // });

        if (newVal < 768) this.mobileMenu = true;
        else this.mobileMenu = false;
      },
    },
  },
  created() {
    window.addEventListener("resize", this.getScreenSize);
  },
  mounted() {
    this.teamLogo = this.getAssets.horizontalTeamLogo;
    this.defaultTabs = this.getAssets.defaultTabs;

    if (this.windowWidth < 768) this.mobileMenu = true;
    else this.mobileMenu = false;

    // this.$nextTick(() => {
    //   this.sideBarPartialHeight();
    // });
  },
  methods: {
    ...mapActions(["fetchUser", "fetchAssets"]),

    redirectToLOReviews() {
      window.open(this.getAssets.lo_reviews_url + "/reviews", "_blank");
    },

    // sideBarPartialHeight() {
    //   this.ulHeight = this.$refs.sidebarElRef.offsetHeight;
    // },

    updateModalValue() {
      eventBus.$emit("modal-text", "Send Mortgage Application");
    },

    getSideBarIcons(value) {
      if (value === "Approval-Letters") return "fa fa-envelope";
      if (value === "Pipeline") return "fa fa-list";
      if (value === "Refer-a-Client") return "fa fa-user-tie";
      if (value === "Condos") return "fas fa-building";
      if (value === "Mortgage-Calculator") return "fa fa-calculator";
      return;
    },

    tabWithPin(value) {
      let thePinColor;

      if (this.getAssets.primaryBrandColor) {
        thePinColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        thePinColor = color.substring(1);
      }

      if (this.getAssets.pinnedTab === value)
        return `https://img.icons8.com/glyph-neue/18/${thePinColor}/pin.png`;
      else return "https://img.icons8.com/plumpy/16/0000F0/pin3.png";
    },

    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },

    async pinTheTab(value) {
      eventBus.$emit("loader-status", true);

      let payLoad = {
        accountId: this.getProspectId,
        defaultSort: "",
        pinnedTab: value,
        orgId: this.getOrgId,
      };

      await this.$http
        .post("/accountAuth/PostConfiguration/", payLoad)
        .then((response) => {
          if (response.data.response === "Success") {
            this.fetchAssets(response.data.assetData);
            this.success("Pinned Tab successfully updated");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    getScreenSize() {
      this.windowWidth = window.innerWidth;
      // this.sideBarPartialHeight();
    },

    toggleMobileMenu() {
      this.toggleSidebar = !this.toggleSidebar;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.getScreenSize);
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.custom-sidebar-div {
  margin: 15px -25px;
}
.custom-sidebar-button {
  width: auto;
  margin: 25px 25px 0;
  font-size: 14px;
}
</style>

